export const DATE_FORMAT = {
  DEFAULT: 'yyyy-MM-dd',
  DATETIME_12_HOUR_WITH_SECONDS: 'yyyy-MM-dd hh:mm:ss a',
  DATETIME_12_HOUR: 'yyyy-MM-dd hh:mm a',
  TIME_24_HOUR: 'HH:mm',
  TIME_12_HOUR: 'HH:mm a',
  DATETIME: `yyyy-MM-ddTHH:mm:ssZ`,
  DATETIME_24_HOUR: 'yyyy-MM-dd HH:mm',
  YEAR_MONTH: 'yyyy-MM',
  YEAR: 'yyyy',
  MONTH_DAY: 'MMM d',
  DAY_MONTH: 'dd-MMM'
}
