export const MESSAGES = {
  confirmLogout:  'Are you sure you want to logout?',
  confirmCreate:  'Are you sure you want to create this {item}?',
  confirmUpdate:  'Are you sure you want to edit this {item}?',
  confirmDelete:  'Are you sure you want to delete this {item}?',
  confirmAssignToMaintenance: 'Are you sure you want to assign this issue to maintenance?',
  confirmSetSeverity: 'Are you sure you want set this severity status to {item}?',
  pageNotFoundTitle: 'Sorry, the page you visited does not exist.',
  successCreate: 'Successfully created this {item}.',
  successUpdate: 'Successfully updated this {item}.',
  successDelete: 'Successfully deleted this {item}.',
  unauthorizedAppAccessTitle: 'You are not authorized to access this app.',
  invalidFile: 'Invalid file type.',
  assignSuccess: 'Issue has been assigned to maintenance.',
  setSeveritySuccess: 'Severity status successfully set to {item}.',
  viewSingleItemTip: 'To continue, click on any item from the list on the left for more details.',
  noAssets: 'No assets in the system.',
  noInspections: 'No inspections in the system.',
  noIssues: 'No issues for this asset.',
};

export const FIELD_ERROR = {
  required: 'This field is required.',
  alphaNumericSpace: 'Only alphanumeric characters and space are allowed.',
  alphaNumericDash: 'Only alphanumeric characters and dash are allowed.'
}

export const constructMessage = (message: string, replacement: string) =>
  message.replace('{item}', replacement);
