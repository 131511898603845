export const MENU = {
  dashboard: 'Dashboard',
  assets: 'Assets',
  issues: 'Issues',
  users: 'Users',
  components: 'Components',
  inspections: 'Inspections',
  settings: 'Settings',
  notifications: 'Notifications',
  profile: 'Profile',
};

export const ACTIONS = {
  yes: 'Yes',
  no: 'No',
  logout: 'Logout',
  create: 'Create',
  confirmation: 'Confirmation',
  update: 'Update',
  edit: 'Edit',
  delete: 'Delete',
  submit: 'Submit',
  cancel: 'Cancel',
  close: 'Close',
  clickToUpload: 'Click to Upload',
  assignToMaintenance: 'Assign to Maintenance',
  search: 'Search',
  clear: 'Clear',
  reset: 'Reset',
}

export const LABELS = {
  asset: 'Asset',
  assetId: 'Asset ID',
  make: 'Make',
  model: 'Model',
  image: 'Image',
  licensePlate: 'License Plate',
  status: 'Status',
  success: 'Success',
  error: 'Error',
  listOfIssues: 'List of Issues',
  issue: 'Issue',
  reported: 'Reported',
  description: 'Description',
  severity: 'Severity',
  setSeverity: 'Set Severity',
  photos: 'Photos',
  advancedSearch: 'Advanced Search',
  component: 'Component',
  dateReported: 'Date Reported',
  from: 'From',
  to: 'To',
  type: 'Type',
  inspection: 'Inspection',
}
