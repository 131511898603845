export const FILE_TYPES = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  SVG: 'image/svg+xml'
}

export const IMAGE_FILE_TYPES = [
  FILE_TYPES.JPEG,
  FILE_TYPES.PNG,
  FILE_TYPES.JPG,
  FILE_TYPES.SVG,
]