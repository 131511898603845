export const MAIN_MODULE_ROUTES = {
  dashboard: 'dashboard',
  assets: 'vehicle-assets',
  issues: 'issues',
  users: 'users',
  components: 'components',
  inspections: 'inspections',
  settings: 'settings',
  notifications: 'notifications',
  profile: 'profile',
};
